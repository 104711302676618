import { navigateTo } from "#app";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.client) {
    const queryParameters = to.query;
    const token = queryParameters.token ? queryParameters.token.toString() : '';
    const error = queryParameters.error;
    const tokenValid = queryParameters.valid;

    const { getDelegateInviteToken } = useDelegates();

    if (error || tokenValid) {
      return;
    }

    if (!token) {
      return navigateTo({ path: '/delegate-access', query: { error: 'Missing token' } });
    }

    try {
      const tokenObject = await getDelegateInviteToken(token);
      if (tokenObject) {
        return navigateTo({
          path: '/delegate-access',
          query: { token: token, valid: 'true', name: tokenObject.account.name, type: tokenObject.token_type },
        });
      } else {
        return navigateTo({ path: to.fullPath, query: { error: 'Invitation could not be accepted' } });
      }
    } catch (error) {
      return navigateTo({ path: to.fullPath, query: { error: 'An error occurred during the operation' } });
    }
  }
});



